export const useOperatorCallingStore = defineStore('operatorCalling', {
  state: () => {
    return {
      number: null,
      hash: null,
      token: null,
    };
  },

  actions: {
    async call(number) {
      this.number = number;

      const response = await useApi.post('/operator/twilio', {
        body: {
          address: number,
          conference: false,
          request: null,
        },
      }).catch((error) => {
        console.error(error);
        const message = error.response?.status === 403 ? `Na číslo ${number} není možné zavolat.` : 'Došlo k chybě při volání';
        ElMessage.error({
          message,
          duration: 4000,
          showClose: true,
        });
      });

      if (response) {
        this.token = response.token;
        this.hash = response.hash;
      }
    },

    cancel() {
      this.number = null;
      this.token = null;
      this.hash = null;
    },
  },

  getters: {
  },
});
